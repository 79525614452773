<template>
  <div class="details-container" v-if="insuranceData.loading">
    <div class="block-module">
      <div class="tab-header">
        <tabs-tmpe />
      </div>
    </div>
    <div class="details-head">
      <div class="head-main">
        <h3 class="title">{{insuranceData.insurance_data.name}}</h3>
        <p class="descriptions">保单号：{{insuranceData.trade_policy_no}}</p>
      </div>
    </div>
    <div class="details-body">
      <div class="info-module applicant-class">
        <div class="im-title">投保人信息</div>
        <div class="im-container">
          <ul class="im-list">
            <li class="il-item">
              <span class="il-key">投保人姓名：</span>
              <span class="il-val">{{insuranceData.person.name}}</span>
            </li>
            <li class="il-item">
              <span class="il-key">证件号：</span>
              <span class="il-val">
                <!-- <span>{{insuranceData.person.certificate_type == 1 ? '身份证' : '护照' }}</span> -->
                <span>{{insuranceData.person.certificate_code}}</span>
              </span>
            </li>
            <li class="il-item">
              <span class="il-key">系统注册账号：</span>
              <span class="il-val">{{insuranceData.person.tel}}</span>
            </li>
            <li class="il-item">
              <span class="il-key">企业单位性质：</span>
              <span class="il-val">{{insuranceData.person.company_property}}</span>
            </li>
            <li class="il-item">
              <span class="il-key">企业经营地址：</span>
              <span class="il-val">{{insuranceData.person.company_scope}}</span>
            </li>
            <li class="il-item">
              <span class="il-key">邮箱：</span>
              <span class="il-val">{{insuranceData.person.email}}</span>
            </li>
            <li class="il-item">
              <span class="il-key">联系人：</span>
              <span class="il-val">{{insuranceData.person.compellation}}</span>
            </li>
            <li class="il-item">
              <span class="il-key">手机号：</span>
              <span class="il-val">{{insuranceData.person.tel}}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="info-module table-class">
        <div class="im-title">人员名单（{{insuranceData.insured.length}}人）</div>
        <div class="im-container">
          <div class="tc-table">
            <el-table
              border
              row-class-name="fake-table-tr"
              :data="insuranceData.insured"
              style="width: 100%"
            >
              <el-table-column
                type="index"
                label="序号">
              </el-table-column>
              <el-table-column
                label-class-name="fake-table-th"
                align="center"
                prop="name"
                label="姓名">
              </el-table-column>
              <el-table-column
                label-class-name="fake-table-th"
                align="center"
                prop="certificate_type"
                label="证件类型">
                  <template slot-scope="scope">
                    <span v-if="scope.row.certificate_type == 1">身份证</span>
                    <span v-else>护照</span>
                  </template>
              </el-table-column>
              <el-table-column
                label-class-name="fake-table-th"
                align="center"
                prop="code"
                label="证件号码">
              </el-table-column>
              <el-table-column
                label-class-name="fake-table-th"
                align="center"
                prop="sex"
                label="性别">
                  <template slot-scope="scope">
                    <span v-if="scope.row.sex == 1">男</span>
                    <span v-else>女</span>
                  </template>
              </el-table-column>
              <el-table-column
                label-class-name="fake-table-th"
                align="center"
                prop="birth"
                label="出生日期">
              </el-table-column>
              <el-table-column
                label-class-name="fake-table-th"
                align="center"
                prop="tel"
                label="手机号码">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="info-module guarantee-class">
        <div class="im-title">保障信息</div>
        <div class="im-container">
          <ul class="im-list">
            <li class="il-item">
              <span class="il-key">保障计划：</span>
              <span class="il-val">{{insuranceData.plan.name}}</span>
            </li>
            <li class="il-item">
              <span class="il-key">保障时间：</span>
              <span class="il-val">{{insuranceData.guarantee_start_time_time}}  至  {{insuranceData.guarantee_end_time_time}}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="info-module pay-class">
        <div class="im-title">支付信息</div>
        <div class="im-container">
          <ul class="im-list">
            <li class="il-item">
              <span class="il-key">保费：</span>
              <span class="il-val">￥{{insuranceData.recognizee_premium}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tabsTmpe from '@/views/insurance/components/tabs-temp/tabs-temp.vue';
export default {
  // 允许组件模板递归地调用自身
  name: '',
  // 声明一组可用于组件实例中的组件
  components: {
    tabsTmpe
  },
  // 一个用于从父组件接收数据的数组或对象
  props: {

  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      page_id: '', // 页面id
      insuranceData: { // 保险数据
        loading: false,
      },
      tableData: [],
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 获取页面数据
     */
    getPageDataFun() {
      this.getPageRouteParamFun().then((id) => {
        // 获取保险数据
        this.getInsuranceDataFun(id);
      })
    },
    /**
     * 获取页面路由参数
     */
    getPageRouteParamFun() {
      return new Promise((resolve,reject) => {
        // 获取当前路由
        this.myGetRouter().then((res) => {
          console.log('获取页面路由参数 res ==',res)
          let { id } = res.query;
          this.page_id = id;
          if(id) {
            resolve(id);
          } else {
            reject();
          }
        });
      })
    },
    /**
     * 获取保险数据
     */
    getInsuranceDataFun(id) {
      this.myRequest({
        url: '/api/insurance/insuredorderdetail',
        data: {
          id: id,
        }
      })
      .then((res) => {
        console.log('获取保险数据 res == ',res)
        let resData = res.data.data;
        this.insuranceData = Object.assign(this.insuranceData,resData);
        this.insuranceData.loading = true;
        console.log('保险新数据 this.insuranceData == ',this.insuranceData)
      }).catch((err) => {
        console.log('获取保险数据 err == ',err)
      })
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    // 获取页面数据
    this.getPageDataFun();
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
@import '@/views/insurance/css/common.scss';
.details-container {
  box-sizing: border-box;
}

.details-head {
  box-sizing: border-box;
  padding: 20px 0;
  border-bottom: 1px solid #CCCCCC;
  .head-main {
    box-sizing: border-box;
    padding: 0 $theme-widht-whiteedge;
    // width: $theme-view-widht;
    max-width: $theme-view-widht;
    min-width: $theme-view-min-widht;
    margin: 0 auto;
    .title {
      font-weight: 700;
      font-size: 24px;
      color: #444343;
    }
    .descriptions {
      margin-top: 18px;
      font-size: 14px;
      color: #787878;
    }
  }
}

.details-body {
  box-sizing: border-box;
  padding: 0 $theme-widht-whiteedge;
  // width: $theme-view-widht;
  max-width: $theme-view-widht;
  min-width: $theme-view-min-widht;
  margin: 0 auto;
  .info-module {
    margin: 20px 0;
    .im-title {
      font-weight: 700;
      font-size: 18px;
      color: #444343;
    }
    .im-list {
      .il-item {
        margin-top: 16px;
        font-size: 16px;
        .il-key {
          color: #787878;
        }
        .il-val {
          color: #AAAAAA;
        }
      }
    }
    &.applicant-class {
      .im-list {
        display: flex;
        flex-wrap: wrap;
        .il-item {
          width: calc(100% / 3);
        }
      }
    }
  }
}

.table-class {
  .tc-table {
    box-sizing: border-box;
    margin-top: 16px;
    &::v-deep {
      .el-table th.el-table__cell {
        background: #F0F0F0;
      }
      .el-table__body-wrapper {
        .el-table__row {
          .el-table_1_column_1 {
            // font-size: 22px;
          }
        }
      }
      .fake-table-th {
        padding: 10px 0;
        background: #F0F0F0;
        font-weight: 400;
        font-size: 18px;
        color: #444343;
      }
      .fake-table-tr {
        font-size: 16px;
        color: #787878;
        background: #fff;
      }
      .el-table--border, .el-table--group {
        border: 1px solid #e6e6e6;
      }
      .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
      .el-table--border th.el-table__cell, .el-table__fixed-right-patch {
        border: none;
      }
      .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
        border-top: 1px solid #e6e6e6;
      }
      // .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
      //   border-right: 1px solid transparent;
      // }
      .el-table--border::after, .el-table--group::after, .el-table::before {
        // background: #1D232B;
        display: none;
      }
      .el-table, .el-table__expanded-cell,
      .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        // background: #ebebeb;
        background: transparent;
      }
      .el-table .el-table__cell {
        height: 66px;
        // padding: 16px 0;
      }
    }
    .vip-sign {
      display: inline-block;
      width: 97px;
      height: 34px;
    }
  }
}
</style>
